// 扫码管理相关接口
import request from '@/plugins/request';

// 扫描流水 =============================================================================
// 品牌商列表
export function getAllSupplier() {
    return request({
        url: '/common/supplier/all',
        method: 'get',
    });
}
// 经销商账户列表
export function getDistributorList(data) {
    return request({
        url: '/auth/user/findDistributorUser',
        method: 'post',
        data,
    });
}

// 扫码流水
// 列表
export function getScanList(data) {
    return request({
        url: '/distributor/scanCode/order/list',
        method: 'post',
        data,
    });
}

// 查询参数
export function getScanListParam(data) {
    return request({
        url: '/distributor/scanCode/order/init',
        method: 'get',
        params: data,
    });
}

// 根据id扫码详情
export function getScanDetail(id) {
    return request({
        url: `/distributor/scanCode/order/detail/${id}`,
        method: 'get',
    });
}

// 查询胎号
export function getBarcodes(data) {
    return request({
        url: `/distributor/scanCode/order/detail`,
        method: 'get',
        params: data,
    });
}

// 扫码流水导出
export function scanListExport(data) {
    return request({
        url: '/distributor/scanCode/order/export',
        method: 'post',
        data,
    });
}

// 经销商扫码异常 ========================================================================
// 列表
export function getDistributorScanList(data) {
    return request({
        url: '/distributor/scanCode/order/abnormal/list',
        method: 'post',
        data,
    });
}

// 查询参数
export function getDistributorScanParam(data) {
    return request({
        url: '/distributor/scanCode/order/abnormal/init',
        method: 'get',
        params: data,
    });
}

// 经销商扫码异常导出
export function DistributorScanExport(data) {
    return request({
        url: '/distributor/scanCode/order/export/abnormal',
        method: 'post',
        data,
    });
}

// 经销商条码记录
export function getScanCodeRecord(data) {
    return request({
        url: '/distributor/scanCode/barCode/findBarcodeRecord',
        method: 'post',
        data,
    });
}

// 条码信息
export function getScanCodeInfos(data) {
    return request({
        url: '/distributor/scanCode/barCode/findBarcodeInfo',
        method: 'post',
        data,
    });
}

// 经销商条码信息
export function getScanCodeInfo(data) {
    return request({
        url: '/distributor/barCodeRecord/findBarcodeInfo',
        method: 'get',
        params: data,
    });
}

// 经销商条码信息查询
export function getScanCodeInfoByBarcode(data) {
    return request({
        url: `/distributor/scanCode/barCode/findBarcodeInfo`,
        method: 'post',
        data,
    });
}

// 扫码进销存
// 查询扫码进销存列表
export function getScanSituationList(data) {
    return request({
        url: '/distributor/scanCodeInOut/listDistributorInOutStatistics',
        method: 'post',
        data,
    });
}

// 扫码进销存列表导出
export function handleScanSituationExport(data) {
    return request({
        url: '/distributor/scanCodeInOut/excelDistributorInOutStatistics',
        method: 'post',
        data,
    });
}
// 门店扫码异常
export function getScanStoreAbnormalList(data) {
    // console.log(data)
    return request({
        url: '/distributor/scan/store/abnormal/page',
        method: 'get',
        params: data,
    });
}

// 扫码流水门店查询-旧 2024.1.19
export function getStoreList(data) {
    return request({
        url: '/distributor/scanCode/order/storeList',
        method: 'get',
        params: data,
    });
}

// 扫码流水门店查询-新 2024.1.19
export function getStoreList1(data) {
    return request({
        url: '/distributor/scanCode/order/storeList/ningbo',
        method: 'get',
        params: data,
    });
}

// 扫码流水增加门店
export function scanAddStore(orderId, storeId) {
    return request({
        url: `/distributor/scanCode/order/addStore?orderId=${orderId}&storeId=${storeId}`,
        method: 'post',
    });
}

// 导出胎号
export function exportTyreCode(orderId) {
    return request({
        url: `/distributor/scanCode/order/export/tyrecode?orderId=${orderId}`,
        method: 'post',
    });
}

// 模板下载
export function temlpateUrl(data) {
    return request({
        url: '/distributor/scanCode/barCode/temlpateUrl',
        method: 'post',
        data,
    });
}

// 批量导入
export function importTyreCodes(data) {
    return request({
        url: '/distributor/scanCode/barCode/importTyreCodes',
        method: 'post',
        data,
    });
}

// 分仓异常参数
export function apiWarehouseAbnormal(data) {
    return request({
        url: '/distributor/scanCode/order/warehouseAbnormal/init',
        method: 'get',
        params: data,
    });
}
