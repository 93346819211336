<template>
    <div class="store-sign-list">
        <PageHeader title="门店签署管理" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <TableFormNew
                ref="tableFormNewRef"
                :formList="formList"
                @onSubmit="onSubmit"
                @onReset="onReset"
            ></TableFormNew>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="tableConfig" />
        </Card>
    </div>
</template>

<script>
import TableFormNew from '@/components/tableFormNew';
import tablePage from '@/components/tablePage';
import { renderButton, jumpPage } from '@/libs/util';
import { clearInvalidKey } from '@/libs/util.tool';
import { listEsignStore } from '@/api/esign';
import { yearDateItem } from '@/components/tableFormNew/common/dateItem';
import { businessStatus, listColumns } from './const';

export default {
    name: 'customer-version-list',
    components: {
        TableFormNew,
        tablePage,
    },
    data() {
        return {
            tableConfig: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    ...listColumns,
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 120,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(
                                    h,
                                    {
                                        text: '详情',
                                        type: 'primary',
                                        disabled: [1, 3, 4, 6, 7, 8, 9, 10].indexOf(p.row.businessStatus) > -1,
                                    },
                                    () => {
                                        this.check(p.row);
                                    },
                                ),
                            ];
                        },
                    },
                ],
                data: [],
            },
            formList: [
                { type: 'input', label: '合同名称', prop: 'contractName' },
                { type: 'input', label: '门店名称', prop: 'storeName' },
                { type: 'input', label: '签署人', prop: 'storeSignerName' },
                { type: 'input', label: '联系方式', prop: 'storeSignerContact' },
                { type: 'select', label: '签署状态', prop: 'businessStatus', options: businessStatus },
                { ...yearDateItem, value: '2025' },
            ],
        };
    },
    activated() {
        this.getData();
    },
    methods: {
        // 获取列表数据
        async getData() {
            try {
                this.tableConfig.loading = true;
                let formData = this.$refs['tableFormNewRef'].getFormData();
                const params = {
                    ...clearInvalidKey(formData),
                    current: this.tableConfig.current,
                    size: this.tableConfig.size,
                };
                const res = await listEsignStore(params);
                let updateType = {
                    0: '-',
                    1: '强制更新',
                    2: '非强制提示更新',
                    3: '非强制不提示更新',
                };
                this.tableConfig.total = Number(res.total);
                this.tableConfig.data = res.records.map((item) => {
                    return {
                        ...item,
                        updateType: updateType[item.updateType],
                    };
                });
                this.tableConfig.loading = false;
            } catch (error) {
                this.tableConfig.loading = false;
            }
        },
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
        // 查询
        onSubmit() {
            this.tableConfig.current = 1;
            this.getData();
        },
        // 重置
        onReset() {
            this.tableConfig.current = 1;
            this.tableConfig.size = window.$pageSize;
            this.getData();
        },
        // 详情
        check(row) {
            let formData = this.$refs['tableFormNewRef'].getFormData();
            jumpPage({
                path: '/sub-vue2/customer/storeSign/check',
                params: {
                    id: row.id,
                    mode: 2,
                    year: formData.year,
                },
            });
        },
    },
};
</script>

<style lang="less">
.store-sign-list {
    .ivu-badge-status-text {
        word-break: break-word;
        white-space: pre-line;
        color: unset;
    }
}
</style>
