<template>
    <div>
        <PageHeader title="经销商扫码异常" hidden-breadcrumb>
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >{{ isExporting ? '正在导出...' : '导出' }}</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import {
    getDistributorScanList,
    getDistributorScanParam,
    DistributorScanExport,
    apiWarehouseAbnormal,
} from '@/api/scan';
export default {
    name: 'scan-distributor',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '条码号', key: 'barcode', minWidth: 160 },
                    { title: '胎号', key: 'tyrecode', minWidth: 160 },
                    { title: '类型', key: 'type', minWidth: 140 },
                    { title: '扫码时间', key: 'createDate', minWidth: 170 },
                    { title: '扫码人', key: 'scanCodeOperateName', minWidth: 150 },
                    { title: '异常提示', key: 'abnormalValue', minWidth: 320 },
                ],
                data: [],
            },
            formList: [
                { title: '异常类型', type: 'select', key: 'abnormalCode', placeholder: '请选择', options: [] },
                {
                    title: '仓库异常',
                    type: 'select',
                    key: 'warehouseAbnormalCode',
                    placeholder: '请选择',
                    options: [],
                },
                { title: '扫码人', type: 'distributorSelect', key: 'operatorId' },
                { title: '时间', type: 'daterange', key: ['startDate', 'endDate'] },
            ],
        };
    },
    activated() {
        this.getWarehouseAbnormalOption();
        this.getTypeOption();
        this.getData();
    },
    methods: {
        // 异常类型 options
        async getTypeOption() {
            const res = await getDistributorScanParam();
            const _list = res.map((item) => ({ id: item.key, name: item.value }));
            const options = [{ id: '-9999', name: '全部' }, ..._list];
            this.formList[0].options = options;
        },
        // 分仓异常 options
        async getWarehouseAbnormalOption() {
            const res = await apiWarehouseAbnormal();
            const _options = (res || []).map((item) => {
                return {
                    id: item.key,
                    name: item.value,
                };
            });
            this.formList[1].options = _options;
        },
        // 获取列表
        async getData(params = {}) {
            this.list.loading = true;
            const _params = {
                ...params,
                abnormalCode: params.abnormalCode != '-9999' ? params.abnormalCode : null,
                current: this.list.current,
                size: this.list.size,
            };
            try {
                const res = await getDistributorScanList(_params);
                this.list.total = Number(res.total);
                this.list.data = (res.list || []).map((item) => {
                    return {
                        ...item,
                        barcode: item.barcode || '-',
                        tyrecode: item.tyrecode || '-',
                        type: item.type || '-',
                        createDate: item.createDate || '-',
                        scanCodeOperateName: item.scanCodeOperateName || '-',
                        abnormalValue: item.abnormalValue || '-',
                    };
                });
                this.list.loading = false;
            } catch (error) {
                this.list.loading = false;
            }
        },
        onSubmit(param) {
            this.list.current = 1;
            this.list.data = [];
            this.getData(param);
        },
        onPageChange(val) {
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.total,
                ...form,
            };
            DistributorScanExport(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
    },
};
</script>
