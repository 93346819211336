import _ from 'lodash';

/**
 * @description 判断-是否是有效的数据
 * @param {any} val 传入的值
 * @returns {boolean} 返回boolean
 */
const isValidVal = (val) => {
    if ([null, 'null', undefined, 'undefined', ''].includes(val)) {
        return false;
    }
    return true;
};

/**
 * @description 判断-是否是有效的非空数组
 * @param {any} list 传入的值
 * @returns {boolean} 返回boolean
 */
const isValidArr = (list) => {
    if (_.isArray(list) && !_.isEmpty(list)) {
        return true;
    }
    return false;
};

// 清空无效的key
const clearInvalidKey = (data, isCLearEmptyArray = true) => {
    let _data = { ...data };
    for (let key in _data) {
        if ([null, 'null', undefined, 'undefined', ''].includes(_data[key])) {
            delete _data[key];
        }
        if (isCLearEmptyArray && _.isArray(data[key]) && _.isEmpty(data[key])) {
            delete _data[key];
        }
    }
    return _data;
};

/**
 * @description 浏览器下载文件
 * @param {String} url 文件地址/文件流
 * @param {String } fileName 文件名（文件流必传）
 * @param {Boolean } isFileStream 是否是文件流
 */
const downloadFile = (url, fileName, isFileStream = false) => {
    const downloadByBlob = (blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName || url.split('/').pop().split('?')[0];
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
    };
    if (isFileStream) {
        const blob = new Blob([url], { type: 'application/octet-stream' });
        downloadByBlob(blob);
        return;
    }
    fetch(url)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.blob();
        })
        .then((blob) => {
            downloadByBlob(blob);
        })
        .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
        });
};

export { isValidVal, isValidArr, clearInvalidKey, downloadFile };
