// 签署状态
const businessStatus = [
    { label: '签署中', value: 1 },
    { label: '已签署', value: 2 },
    { label: '合同失效', value: 3 },
    { label: '签署失败', value: 4 },
    { label: '合同作废', value: 5 },
    { label: '升级中', value: 6 },
    { label: '升级失败', value: 7 },
    { label: '待审核', value: 8 },
];

// 列表表头
const listColumns = [
    { title: '合同名称', key: 'contractName', minWidth: 220, fixed: 'left' },
    { title: '门店名称', key: 'storeName', minWidth: 180 },
    { title: '签署人', key: 'storeSignerName', minWidth: 80 },
    { title: '签约条数', key: 'monthFinishValue', minWidth: 100 },
    { title: '签署人联系方式', key: 'storeSignerContact', minWidth: 140 },
    { title: '签署人身份证号码', key: 'storeSignerCard', minWidth: 180 },
    {
        title: '签署状态',
        key: 'flowStatus',
        minWidth: 250,
        render: (h, p) => {
            return h(
                'div',
                {
                    class:
                        {
                            1: 'processing',
                            2: 'success',
                            3: 'warning',
                            4: 'error',
                            5: 'error',
                            6: 'processing',
                            7: 'error',
                            8: 'processing',
                            9: 'processing',
                            10: 'warning',
                        }[p.row.businessStatus] + ' sign-status',
                },
                {
                    1: '签署中\n(待门店签署)',
                    2: '签署完成',
                    3: '合同失效',
                    4: '签署失败',
                    5: '合同作废',
                    6: '升级中\n(待门店签署)',
                    7: '升级失败',
                    8: '签署中\n(待经销商签署)',
                    9: '升级中\n(待经销商签署)',
                    10: '待审核',
                }[p.row.businessStatus],
            );
        },
    },
];

export { businessStatus, listColumns };
