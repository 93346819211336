<template>
    <div class="warehouse-entry-list">
        <PageHeader title="入库管理" hidden-breadcrumb>
            <Button slot="action" type="success" class="ivu-ml" @click="onFillOrder">入库单补录</Button>
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >{{ isExporting ? '正在导出...' : '导出' }}</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <Alert show-icon closable class="com-alert">
                审核未通过
                <span style="color: red; padding: 0 5px">{{ failedNum || '-' }}</span>
                条 / 待审核
                <span style="color: #ee9900; padding: 0 5px">{{ auditNum || '-' }}</span>
                条 / 审核通过
                <span style="color: green; padding: 0 5px">{{ passNum || '-' }}</span>
                条
            </Alert>
            <table-page
                class="table-s"
                disHover
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
                :list="list"
            />
        </Card>
        <FillOrder ref="FillOrder" />
        <TypeEdit @getData="getData" ref="typeEdit" />
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { WareHouseEntryList, WareHouseEntryEdit, WareHouseEntryExport, outboundCount } from '../../../api/warehouse.js';
import { renderButton, jumpPage } from '@/libs/util';
import FillOrder from '../components/FillOrder';
import TypeEdit from '../components/TypeEdit';
export default {
    name: 'warehouse-entry-list',
    components: { tableForm, tablePage, FillOrder, TypeEdit },
    data() {
        return {
            auditNum: '-',
            failedNum: '-',
            passNum: '-',
            orderVisible: false,
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: 'ERP单号',
                        key: 'sn',
                        align: 'center',
                        fixed: 'left',
                    },
                    {
                        title: '订单类型',
                        key: 'type',
                        align: 'center',
                        width: '120',
                        render: (h, p) => {
                            return <p>{p.row.type === 1 ? '直发订单' : '普通订单'}</p>;
                        },
                    },
                    {
                        title: '数量',
                        key: 'totalQuantity',
                        align: 'center',
                        width: '120',
                    },
                    {
                        title: '地址',
                        key: 'receiveAddress',
                        align: 'center',
                    },
                    {
                        title: '仓库',
                        key: 'warehouseSn',
                        width: 140,
                        render: (h, p) => {
                            if (!p.row.warehouseSn) {
                                return h('div', {}, '-');
                            }
                            return h('div', {}, [
                                h('span', {}, p.row.warehouseSn),
                                h('span', { style: { color: '#ddd', display: 'inline-block', padding: '0 7px' } }, '|'),
                                h('span', {}, p.row.warehouseName),
                            ]);
                        },
                    },
                    {
                        title: '仓库类型',
                        key: 'isTotal',
                        render: (h, p) => {
                            return h('div', p.row.isTotal ? '总仓' : p.row.isTotal == null ? ' -' : '分仓');
                        },
                        width: 100,
                    },
                    {
                        title: '审核状态',
                        key: 'type',
                        align: 'center',
                        width: '150',
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    status: {
                                        '': 'default',
                                        0: 'default',
                                        1: 'processing',
                                        2: 'success',
                                        3: 'error',
                                    }[p.row.status],
                                    text: {
                                        '': '-',
                                        0: '-',
                                        1: '待审核',
                                        2: '审核通过',
                                        3: '审核未通过',
                                    }[p.row.status],
                                },
                            });
                            let info =
                                p.row.status === 3
                                    ? h('Icon', {
                                          class: 'reason-modal',
                                          props: {
                                              type: 'ios-information-circle',
                                              size: 19,
                                          },
                                          on: {
                                              click: () => {
                                                  this.$Modal.warning({
                                                      title: '驳回原因',
                                                      content: p.row.reason,
                                                      closable: true,
                                                      okText: '关闭',
                                                  });
                                              },
                                          },
                                      })
                                    : null;
                            return h('div', [badge, info]);
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        render: (h, p) => {
                            return (
                                <div>
                                    {renderButton(h, { text: '详情', type: 'primary' }, () => {
                                        this.detail(p.row);
                                    })}
                                    {p.row.type === 0 &&
                                        renderButton(
                                            h,
                                            {
                                                text: '变更类型',
                                                type: 'warning',
                                                disabled: p.row.status === 1 || p.row.status === 2,
                                            },
                                            () => {
                                                this.changeType(p.row);
                                            },
                                        )}
                                </div>
                            );
                        },
                    },
                ],
                /****
                 * 初始状态且是普通订单则显示变更类型
                 * 审核中或直发订单则不显示变更类型
                 */
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '订单类型',
                    type: 'select',
                    key: 'type',
                    clearable: true,
                    options: [
                        { id: 0, name: '普通入库单' },
                        { id: 1, name: '直发入库单' },
                    ],
                },
                {
                    title: '入库时间',
                    type: 'daterange',
                    key: ['beginDate', 'endDate'],
                    width: 300,
                },
                {
                    title: 'ERP单号',
                    type: 'input',
                    key: 'sn',
                    placeholder: '请输入单号',
                },
                {
                    title: '审核状态',
                    type: 'select',
                    key: 'status',
                    clearable: true,
                    options: [
                        { id: 1, name: '待审核' },
                        { id: 2, name: '审核通过' },
                        { id: 3, name: '审核未通过' },
                    ],
                },
            ],
        };
    },
    methods: {
        getData(p) {
            this.list.loading = true;
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            WareHouseEntryList(params)
                .then((res) => {
                    this.list.total = Number(res.total);
                    this.list.data = (res.list || []).map((item) => {
                        return {
                            ...item,
                            receiveAddress: item.receiveAddress || '-',
                            source: 'ERP导入',
                            isShowMore: false,
                            items: [],
                            stockOutSn: item.sn,
                        };
                    });
                    this.list.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list.loading = false;
                });
            outboundCount().then(
                (res) => {
                    const { auditNum, failedNum, passNum } = res;
                    this.auditNum = auditNum;
                    this.failedNum = failedNum;
                    this.passNum = passNum;
                },
                () => {
                    this.auditNum = 0;
                    this.failedNum = 0;
                    this.passNum = 0;
                },
            );
        },
        onPageChange(val) {
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onSubmit(params) {
            this.list.current = 1;
            this.getData(params);
        },
        detail(row) {
            jumpPage({
                path: '/sub-vue2/warehouse/entry/edit',
                params: { id: row.outboundId, stockOutSn: row.stockOutSn },
            });
        },
        showDetail(row) {
            row.isShowMore = !row.isShowMore;
            if (row.isShowMore && row.items.length === 0) {
                WareHouseEntryEdit(row.id).then((data) => {
                    row.items = data.items || [];
                });
            }
        },
        changeType(row) {
            this.$refs['typeEdit'].open('EDIT', row);
        },
        onFillOrder() {
            this.$refs['FillOrder'].open();
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.total,
                ...form,
            };
            WareHouseEntryExport(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
    },

    activated() {
        this.list.current = 1;
        let form = this.$refs['table-form'].getFormData();
        this.getData(form);
    },
};
</script>

<style scoped lang="less">
.reason-modal {
    cursor: pointer;
}
</style>
